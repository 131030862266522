<template>
  <div class="home-container">
    <div style="width: 100%;padding: 60px 120px;box-sizing: border-box">
      <div class="flex-row" style="flex-wrap: wrap">
        <div class="og-item" @click="gotoTargetView('/students')"> Student Learning Progress</div>
        <div class="error-statistics-item" @click="gotoTargetView('/error-statistics')">Student Wrong Answers</div>
        <div class="class-list-item" @click="gotoTargetView('/classes')">Official Guides</div>
      </div>
    </div>
  </div>
</template>

<script>

import {getUserType} from "../utils/store"
import '../assets/common/font.css'

export default {
  name: "Home",
  data() {
    return {
      login_type: getUserType()
    }
  },
  mounted() {
    if (this.login_type == 'student') {
      this.$router.replace('/classes')
    }
  },
  methods: {
    gotoTargetView(target) {
      this.$router.push({
        path: target
      })
    }
  }
}
</script>

<style scoped lang="less">
.home-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 92vh;
  overflow-y: auto;
}

.og-item {
  margin-right: 80px;
  cursor: pointer;
  background-image: url("../assets/og-bg.png");
  width: 460px;
  height: 264px;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 26px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F;
  padding: 50px 30px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.error-statistics-item {
  margin-right: 80px;
  cursor: pointer;
  background-image: url("../assets/error-statistics-bg.png");
  width: 460px;
  height: 264px;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 26px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F;
  padding: 50px 30px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.class-list-item {
  cursor: pointer;
  background-image: url("../assets/class-list-bg.png");
  width: 460px;
  height: 264px;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 26px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #092E7F;
  padding: 50px 30px;
  box-sizing: border-box;
  margin-bottom: 40px;
}
</style>
